<template>
  <div class="outer-page">
    <x-table
      :no-data-text="CA('threshold_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="500"
      @on-visible-change="modalShow"
    >
      <Form :label-width="120" ref="form" :model="form" :rules="rules">
        <FormItem label="类型" prop="type">
          <Select
            style="width: 300px"
            placeholder="请选择类型"
            v-model="form.type"
            :disabled="this.form.id ? true : false"
          >
            <Option :value="1">承载力阀值</Option>
            <Option :value="2">生产力阀值</Option>
          </Select>
        </FormItem>
        <FormItem label="阀值" prop="value">
          <Input
            style="width: 300px"
            placeholder="请输入阀值"
            v-model="form.value"
          ></Input>
        </FormItem>
        <FormItem label="基地场景" prop="farmScene">
          <Cascader
            :data="farmscene"
            style="width: 300px"
            v-model="form.farmScene"
            :disabled="this.form.id ? true : false"
          ></Cascader>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import CU from "@/common/util";
import { relativeTimeThreshold } from "moment";
export default {
  name: "",
  components: {},
  data() {
    return {
      table: {
        columns: [
          {
            title: "阀值",
            minWidth: 200,
            key: "value",
          },
          {
            title: "基地场景",
            render: (h, { row }) => {
              if (!row.sceneName) return <span>{row.farmName}</span>;
              return <span>{row.farmName + "/" + row.sceneName}</span>;
            },
          },
          {
            title: "创建时间",
            key: "createTime",
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("threshold_update") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("threshold_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.delete(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        type: "",
        //阀值
        value: "",
        farmId: "",
        sceneId: "",
        farmScene: [],
      },
      rules: {
        type: [{ required: true, message: "请选择类型" }],
        value: [{ required: true, message: "请填写阀值" }],
        farmScene: [{ required: true, message: "请选择基地场景" }],
      },
      search_data: {},
      farmscene: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增阀值",
            ca: "threshold_add",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "基地场景",
              component: "cascader",
              field: "farmScene",
              defaultValue: [],
              data: [],
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    search(data) {
      this.search_data = { ...data };
      this.config.page.pageNo = 1;
      this.getList();
    },

    add() {
      this.modal = {
        show: true,
        title: "新增阀值信息",
        submitLoading: false,
      };
    },

    pageChange(pageNo) {
      relativeTimeThreshold.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },

    getList() {
      let farmId = "";
      let sceneId = "";
      if (this.search_data.farmScene) {
        farmId = this.search_data.farmScene[0];
        sceneId = this.search_data.farmScene[1];
      }
      if (!this.CA("threshold_check")) return;
      this.table.loading = true;
      this.$post(this.$api.THRESHOLD_MANAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        farmId: farmId,
        sceneId: sceneId,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.farmScene = [row.farmId, row.sceneId];
      this.modal = {
        show: true,
        title: "编辑阀值信息",
        submitLoading: false,
      };
    },
    delete(id) {
      this.$post(this.$api.THRESHOLD_MANAGE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    getFarmScene() {
      this.$post(this.$api.PRODUCTION_AREA.FARMSCENE).then((res) => {
        this.farmscene = CU.formatTreeData(res, "id", "name", "sceneList");
        this.config.filter.filterBox[0].data = CU.formatTreeData(
          res,
          "id",
          "name",
          "sceneList"
        );
      });
    },
    submit() {
      let params = this.form;
      params.farmId = this.form.farmScene[0];
      params.sceneId = this.form.farmScene[1];
      let url;
      if (params.id) {
        url = this.$api.THRESHOLD_MANAGE.UPDATE;
      } else {
        url = this.$api.THRESHOLD_MANAGE.ADD;
      }
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? "编辑成功！" : "添加成功！");
            this.getList();
            this.modal.show = false;
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        type: "",
        //阀值
        value: "",
        farmId: "",
        sceneId: "",
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getList();
    this.getFarmScene();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>